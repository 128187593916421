import { withAnalytics } from '@praxis/component-analytics'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import urlon from 'urlon'

import {
  cardRender,
  clearCardWithoutRerender,
  delCard,
  getCardMeta,
  getDrillCardMeta,
  getSmartExportData,
  restoreCard,
  restoreDrillCard,
  saveCardFromViewer,
  updateCardInfoStatusWithoutRender,
  updateCardWithoutRerender,
} from '../../../../ducks/cards'

import {
  clearDeleteObjectNotificationStatus,
  clearNotificationCache,
  getNotificationCount,
  getObjectNot,
} from '../../../../ducks/notification'

import { updateCardsDataset } from '../../../../ducks/builder'
import { clearAllFilters, updateAppliedFilters } from '../../../../ducks/filter'
import { displayServiceErrorMessage } from '../../../../ducks/layout'
import { getFilterMetadata } from '../../../../ducks/metadata'
import { getUserAccess } from '../../../../ducks/user'
import { getUniqueFilters, prepareFilterPayload, updateDefaultFilters } from '../../../shared/FilterViewer/filter-utils'

import { dateFilter, getAnchorDateFilter } from '../../../../ducks/timeperiod'
import analyticsConfig from '../../../analytics'
import { getSecondaryTimePeriodColumn } from '../../../shared/TimePeriod/TimePeriodUtils'
import NotFoundPage from '../../NotFoundPage/NotFoundPage'
import Cardviewer from './Cardviewer'

export class CardviewerContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      drill: [],
      filters: [],
      timePeriod: null,
      secondaryTimePeriod: null,
      swapDrillColumn: {},
      preloadedData: null,
      drillthrough: [],
      topLevelDrillSavedInfo: {
        filters: [],
        timePeriod: {},
      },
      activeDrillthroughIndex: -1,
      topLevelId: null,
      drillthroughRenderBypass: false,
      isTimePeriodDrillThrough: false,
      drillthroughBreadCrumbBypass: false,
      drillthroughRenderOverride: false,
      drillthroughMountBypass: false,
      isBadShortUrl: false,
      selectedTimePeriod: null,
      executeAs: null,
      searchFilters: null,
    }
  }

  paramsToObject(entries) {
    const result = {}
    for (const [key, value] of entries) {
      if (key !== 'execute_as') {
        try {
          result[key] = JSON.parse(value.replace(/'/g, '"'))
        } catch (err) {
          // eslint-disable-next-line no-console
          console.warn(err)
        }
      }
    }
    return result
  }

  componentDidMount() {
    const {
      nonRoute,
      routeProps,
      data,
      getCardMeta,
      setHeaderTitle,
      filterMetadataStatus,
      userAccessStatus,
      getFilterMetadata,
      getUserAccess,
    } = this.props
    // If the component is not rendered via a route, this gets the cardId via a prop
    const cardId = nonRoute ? this.props.cardId : routeProps.match.params._id

    /*
      The TL:DR here is that notification data will be passed down from the dashboard to the card preview
      If that is the case, it should be a thumbnail and the prop getNotificationCountStatus should have data

      If it isnt the case, the second check goes about grabbing the notification count for the card
      -Arthur/49754518

    */

    if (data && !this.state.preloadedData) {
      this.setState({
        preloadedData: data,
      })
    } else if (!isNaN(cardId)) {
      const searchParams = new URLSearchParams(routeProps.location.search)

      if (searchParams.has('execute_as')) {
        const executeAs = encodeURIComponent(searchParams.get('execute_as'))
        this.setState({ executeAs, cardId })
        getCardMeta({ id: cardId, executeAs })
      } else {
        getCardMeta({ id: cardId })
        this.setState({ cardId })
      }
      if (routeProps) {
        setHeaderTitle('Card View')
      }
    }

    if (!filterMetadataStatus || !Object.keys(filterMetadataStatus).length) {
      getFilterMetadata()
    }

    if (!userAccessStatus || !Object.keys(userAccessStatus).length) {
      getUserAccess()
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      cardInfoStatus,
      appliedFilters,
      cardId,
      data,
      setHeaderTitle,
      cardsDatasetStatus,
      updateCardsDataset,
      dateFilterStatus,
      dateFilter,
      getDrillCardMeta,
      routeProps,
      updateAppliedFilters,
      anchorDateFilterStatus,
      getAnchorDateFilter,
      siteObj,
      isHomePage,
      print,
      thumbnail,
      getNotificationCountStatus,
      clearNotificationCache,
      deleteObjectNotificationStatus,
    } = this.props
    const {
      activeDrillthroughIndex,
      drillthroughRenderBypass,
      drillthroughRenderOverride,
      drillthroughBreadCrumbBypass,
      drillthroughMountBypass,
    } = this.state

    const searchParams = new URLSearchParams(routeProps.location.search)

    let urlState, nextUrlState, processedNextUrlState

    if (searchParams.has('filters') || searchParams.has('time_period') || searchParams.has('secondary_time_periods')) {
      const entries = this.paramsToObject(searchParams.entries())
      const filters = {
        filters: entries.filters,
        timePeriod: entries.time_period,
        secondaryTimePeriod: entries.secondary_time_periods,
      }
      this.setState({ searchFilters: filters })
      processedNextUrlState = urlon.stringify(filters)
    } else {
      urlState = `${routeProps.location.search}${routeProps.location.hash}`
      nextUrlState = `${nextProps.routeProps.location.search}${nextProps.routeProps.location.hash}`
      processedNextUrlState = nextUrlState.slice(1, nextUrlState.length)
    }

    let decodedNextUrlState = {}

    try {
      decodedNextUrlState = processedNextUrlState ? urlon.parse(processedNextUrlState) : {}
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error, 'caught error in parsing the URLstate for cardviewer container')
    }

    if (nextProps.cardId !== cardId) {
      this.setState({
        cardId: nextProps.cardId,
      })
      this.reinitializeComponent(nextProps.cardId)
    }

    if (drillthroughMountBypass && nextProps.cardInfoStatus.status >= 200 && nextProps.cardInfoStatus.status < 300) {
      const drillIndex = decodedNextUrlState.activeDrillthroughIndex
      const isDeleted =
        nextProps.cardInfoStatus.data.status && nextProps.cardInfoStatus.data.status.state === 'disabled'
      const drillCard = nextProps.cardInfoStatus.data.card_query_attribute.drillthrough.find(
        (el, ind) => ind === drillIndex
      )
      const drillId = drillCard ? drillCard.card_id : cardId

      this.setState(
        {
          drillthroughMountBypass: false,
          activeDrillthroughIndex: drillIndex,
          cardId: drillId,
          filters: decodedNextUrlState.filters || [],
          timePeriod: decodedNextUrlState.timePeriod ||
            decodedNextUrlState.timePeriod || {
              granularity: 'All',
              interval: 'last segment',
              type: 'relative',
            },
        },
        () => {
          if (decodedNextUrlState.filters && decodedNextUrlState.filters.length) {
            updateAppliedFilters(decodedNextUrlState.filters)
          } else if (!isDeleted) {
            this.cardRender()
          }
        }
      )
    }

    if (
      nextProps.getNotificationCountStatus?.status >= 200 &&
      nextProps.getNotificationCountStatus?.status < 300 &&
      !isEqual(getNotificationCountStatus.status, nextProps.getNotificationCountStatus.status) &&
      !isHomePage &&
      !print &&
      !thumbnail
    ) {
      // We dont want to fire a request for Thumbnails here. That is handled on mount - Arthur/49754518
      const cardNotCount = nextProps.getNotificationCountStatus?.data[this.state.cardId || this.props.cardId]
      const datasetNotCount =
        nextProps.getNotificationCountStatus?.data[cardInfoStatus?.data?.card_attribute?.dataset_id]
      if (deleteObjectNotificationStatus?.status !== 'requested' && (cardNotCount === 0 || datasetNotCount === 0)) {
        clearNotificationCache()
      }

      if (cardNotCount > 0) {
        this.props.getObjectNot({
          objectId: this.state.cardId || this.props.cardId,
          type: 'card',
        })
      }
      if (datasetNotCount > 0) {
        this.props.getObjectNot({
          objectId: cardInfoStatus?.data?.card_attribute?.dataset_id,
          type: 'dataset',
        })
      }
    }

    if (
      nextProps.cardInfoStatus.status >= 200 &&
      nextProps.cardInfoStatus.status < 300 &&
      cardInfoStatus.status !== nextProps.cardInfoStatus.status
    ) {
      if (!isHomePage && !print && !thumbnail) {
        // if its the home page or a thumbnail, notification data will be passed into other areas
        // Just dont even bother if its a print page
        // - Art/49754518

        this.props.getNotificationCount({
          cardId: this.state.cardId,
          datasetId: nextProps.cardInfoStatus?.data?.card_attribute?.dataset_id,
        })
      }

      const cardQueryAttribute = nextProps.cardInfoStatus.data.card_query_attribute
      if (activeDrillthroughIndex === -1) {
        this.setState({
          drillthrough: cardQueryAttribute && cardQueryAttribute.drillthrough,
          topLevelId: nextProps.cardInfoStatus.data._id,
          isTimePeriodDrillThrough: false,
        })

        if (cardQueryAttribute && cardQueryAttribute.drillthrough) {
          nextProps.cardInfoStatus.data.card_query_attribute.drillthrough.forEach(drillCard => {
            if (drillCard.card_id) {
              getDrillCardMeta({ id: drillCard.card_id })
            }
          })
        }
      }

      this.initializeFilters(nextProps.cardInfoStatus.data)

      if (!dateFilterStatus || !Object.keys(dateFilterStatus).length) {
        dateFilter()
      }

      // If there's no timeperiod on "mount", replace it with the results from the meta for its default timeperiod and also if filters are not passed from dashboard then if savefilers should be applied if available
      if (decodedNextUrlState) {
        const newUrlState = {
          ...decodedNextUrlState,
          timePeriod: decodedNextUrlState.timePeriod || nextProps.cardInfoStatus.data.card_query_attribute?.time_period,
        }

        if (
          !decodedNextUrlState.filters ||
          (Array.isArray(decodedNextUrlState.filters) && !decodedNextUrlState.filters.length)
        ) {
          const savedFilters = nextProps.cardInfoStatus.data.card_query_attribute?.saved_filters

          if (savedFilters && savedFilters.length) {
            newUrlState.filters = savedFilters
          }
        }

        if (nextProps.cardInfoStatus.data.card_attribute?.viz_type?.toLowerCase() !== 'text') {
          if (routeProps.location.search === '?$invalidShortUrl=true') {
            this.setState(
              {
                isBadShortUrl: true,
              },
              () => {
                this.setState({
                  isBadShortUrl: false,
                })
              }
            )

            delete newUrlState.invalidShortUrl
          }

          routeProps.history.replace(`${routeProps.match.url}${`?${urlon.stringify(newUrlState)}`}`)
        }
      }
      const { _siteName } = routeProps.match.params
      if (_siteName && siteObj && siteObj.status >= 200 && siteObj.status < 300) {
        setHeaderTitle(siteObj?.data?.name)
      } else {
        setHeaderTitle(nextProps.cardInfoStatus.data.card_attribute.card_title, true)
      }

      const datasetId = nextProps.cardInfoStatus?.data?.card_attribute?.dataset_id
      if (
        (!cardsDatasetStatus || !Object.keys(cardsDatasetStatus).length || !cardsDatasetStatus[datasetId]) &&
        nextProps.cardInfoStatus.data.card_attribute.viz_type?.toLowerCase() !== 'text'
      ) {
        updateCardsDataset(`${datasetId}?field_groups=quick_metadata`)
      }

      if (!anchorDateFilterStatus || !Object.keys(anchorDateFilterStatus).length) {
        /* eslint-disable camelcase */
        const timePeriodObj = nextProps.cardInfoStatus?.data?.card_query_attribute?.time_period
        if (timePeriodObj?.anchor_date && timePeriodObj?.anchor_date !== anchorDateFilterStatus?.anchor_date) {
          getAnchorDateFilter({ anchor_date: timePeriodObj.anchor_date })
        }
        /* eslint-enable camelcase */
      }
    }

    // Handle changes in Filters
    if (nextProps.thumbnail || !isEqual(nextProps.data, data)) {
      this.setState({
        preloadedData: nextProps.data,
      })
    } else {
      if (urlState !== nextUrlState) {
        const processedUrlState = urlState.slice(1, urlState.length)
        const processedNextUrlState = nextUrlState.slice(1, nextUrlState.length)

        // Fires on update/not mount
        try {
          const decodedUrlState = processedUrlState ? urlon.parse(processedUrlState) : {}
          const decodedNextUrlState = processedNextUrlState ? urlon.parse(processedNextUrlState) : {}

          this.setState(
            prevState => ({
              activeDrillthroughIndex: Number.isInteger(decodedNextUrlState.activeDrillthroughIndex)
                ? decodedNextUrlState.activeDrillthroughIndex
                : -1,
              cardId: Number.isInteger(decodedNextUrlState.activeDrillthroughIndex)
                ? prevState.drillthrough[decodedNextUrlState.activeDrillthroughIndex].card_id
                : prevState.topLevelId.toString(),
              timePeriod: decodedNextUrlState.timePeriod || prevState.timePeriod || {},
            }),
            () => {
              if (!isEqual(decodedUrlState.filters, decodedNextUrlState.filters)) {
                // handle situation where only time should be passed as filter
                if (
                  (!decodedUrlState.filters ||
                    (Array.isArray(decodedUrlState.filters) && !decodedUrlState.filters.length)) &&
                  (!decodedNextUrlState.filters ||
                    (Array.isArray(decodedNextUrlState.filters) && !decodedNextUrlState.filters.length))
                ) {
                  this.cardRender()
                } else {
                  updateAppliedFilters(decodedNextUrlState.filters)
                }
              } else if (!(!Object.keys(decodedUrlState).length && Object.keys(decodedNextUrlState).length)) {
                if (decodedNextUrlState.filters) {
                  this.setState({ filters: [...decodedNextUrlState.filters] }, () => {
                    this.cardRender()
                  })
                } else {
                  this.cardRender()
                }
              }
            }
          )
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e, 'err in decoding in cardviewer container')
          updateAppliedFilters([])
        }
      } else if (nextProps.appliedFilters) {
        if (drillthroughRenderBypass) {
          this.setState({
            drillthroughRenderBypass: false,
            isTimePeriodDrillThrough: false,
          })
        } else if (
          (!isEqual(nextProps.appliedFilters, appliedFilters) || drillthroughRenderOverride) &&
          !drillthroughBreadCrumbBypass
        ) {
          const updatedFilters = prepareFilterPayload(nextProps.appliedFilters)

          this.setState({ filters: [...updatedFilters], drillthroughRenderOverride: false }, () => {
            this.cardRender()
          })
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { deleteObjectNotificationStatus, clearDeleteObjectNotificationStatus, displayServiceErrorMessage } =
      this.props
    if (
      deleteObjectNotificationStatus &&
      !isEqual(deleteObjectNotificationStatus.status, prevProps.deleteObjectNotificationStatus.status)
    ) {
      if (deleteObjectNotificationStatus.status >= 200 && deleteObjectNotificationStatus.status < 300) {
        clearDeleteObjectNotificationStatus()
        this.callNotificationCount()
      } else if (deleteObjectNotificationStatus?.status === 'failed') {
        displayServiceErrorMessage('Notification delete failed.')
      }
    }
  }

  componentWillUnmount() {
    const { thumbnail, appliedFilters, updateAppliedFilters, clearNotificationCache } = this.props

    if (!thumbnail) {
      clearNotificationCache()
    }

    if (!thumbnail && appliedFilters.length) {
      updateAppliedFilters([])
    }
  }

  reinitializeComponent = id => {
    const { getCardMeta, routeProps, setHeaderTitle } = this.props

    getCardMeta({ id })

    if (routeProps) {
      setHeaderTitle('Card View')
    }
  }

  callNotificationCount = () => {
    const { nonRoute, routeProps, isHomePage, print, thumbnail, cardInfoStatus } = this.props
    const cardId = nonRoute ? this.props.cardId : routeProps.match.params._id
    if (!isHomePage && !print && !thumbnail && cardInfoStatus.status >= 200 && cardInfoStatus.status < 300) {
      // if its the home page or a thumbnail, notification data will be passed into other areas
      // Just dont even bother if its a print page
      // - Art/49754518
      this.props.getNotificationCount({
        cardId,
        datasetId: cardInfoStatus?.data?.card_attribute?.dataset_id,
      })
    }
  }

  updateSelectedTimePeriod = (selectedTime, isSecondaryTimeChanged, onApplyTimePeriod) => {
    const { cardsDatasetStatus, cardInfoStatus, isMobile } = this.props
    const datasetId = cardInfoStatus.data && cardInfoStatus.data?.card_attribute?.dataset_id
    const secondaryTPColumn = getSecondaryTimePeriodColumn(cardsDatasetStatus && cardsDatasetStatus[datasetId]?.data)
    const secondaryTPColumnName = secondaryTPColumn?.field_name

    if (isSecondaryTimeChanged) {
      this.setState(
        {
          selectedTimePeriod: {
            ...this.state.selectedTimePeriod,
            secondaryTimePeriods: {
              [secondaryTPColumnName]: selectedTime,
            },
          },
          secondaryTimePeriod: {
            ...this.state.selectedTimePeriod,
            secondaryTimePeriods: {
              [secondaryTPColumnName]: selectedTime,
            },
          },
        },
        () => {
          // Apply time period changes for mobile
          if (isMobile) {
            onApplyTimePeriod()
          }
        }
      )
    } else {
      this.setState(
        {
          selectedTimePeriod: {
            ...this.state.selectedTimePeriod,
            timePeriod: selectedTime,
          },
        },
        () => {
          if (isMobile) {
            onApplyTimePeriod()
          }
        }
      )
    }
  }

  clearSelectedTimePeriod = () => {
    this.setState({
      selectedTimePeriod: null,
    })
  }

  initializeFilters(cardMeta) {
    const { timePeriod, drillthrough, searchFilters, secondaryTimePeriod } = this.state
    const { updateAppliedFilters, routeProps } = this.props
    const urlState = `${routeProps.location.search}${routeProps.location.hash}`
    let processedUrlState = urlState.slice(1, urlState.length)
    if (searchFilters) {
      processedUrlState = urlon.stringify(searchFilters)
    }

    const isDeleted = cardMeta.status && cardMeta.status.state === 'disabled'
    let savedFilters = []
    let timePeriodValue
    let secTimePeriodValue

    if (cardMeta.card_query_attribute) {
      const queryAttribute = cardMeta.card_query_attribute

      if (queryAttribute.saved_filters && queryAttribute.saved_filters.length) {
        const _savedFilters = cloneDeep(queryAttribute.saved_filters)
        const savedFilterList = _savedFilters.map(filter => {
          if (filter.ref_id && queryAttribute.columns) {
            const filterColIndex = queryAttribute.columns.findIndex(col => col.ref_id === filter.ref_id)
            if (filterColIndex > -1) {
              filter.dimension = queryAttribute.columns[filterColIndex].field_name
            }
          }
          return filter
        })

        savedFilters = updateDefaultFilters(savedFilterList)

        updateAppliedFilters(savedFilters)
      }

      timePeriodValue =
        timePeriod && Object.keys(timePeriod).length
          ? timePeriod
          : queryAttribute.time_period
          ? queryAttribute.time_period
          : {}

      secTimePeriodValue = !isEmpty(secondaryTimePeriod) ? secondaryTimePeriod : queryAttribute.secondary_time_periods
      /* eslint-disable no-prototype-builtins */
      if (queryAttribute.time_period && !queryAttribute.time_period.hasOwnProperty('granularity')) {
        delete timePeriodValue.granularity
      }
      /* eslint-enable no-prototype-builtins */
    }

    // if saved filters are present render card will run from componentWillReceiveProps
    if (savedFilters.length && !routeProps.location.search) {
      this.setState(
        {
          filters: savedFilters,
          timePeriod: timePeriodValue,
          secondaryTimePeriod: {
            secondaryTimePeriods: secTimePeriodValue,
          },
        },
        () => {
          const urlStates = urlon.stringify({
            filters: prepareFilterPayload(savedFilters).map(filt => {
              delete filt.saved_filter_type

              return filt
            }),
            timePeriod: timePeriodValue,
          })
          routeProps.history.replace(`${routeProps.match.url}${`?${urlStates}`}`)
        }
      )

      // if no saved filters or filters passed in route are present force render card
    } else if ((!drillthrough || !drillthrough.length) && !isDeleted) {
      let decodedUrlState

      // This directly parses whatever is in the URL querystring, which is not necessarily valid json
      // Fires on component did mount/directly linked
      let isBypassSavedFilters

      try {
        decodedUrlState = processedUrlState ? urlon.parse(processedUrlState) : {}
        isBypassSavedFilters = decodedUrlState.bypassDefaultFilters

        if (Number.isInteger(decodedUrlState.activeDrillthroughIndex) && decodedUrlState.activeDrillthroughIndex > -1) {
          this.setState({
            drillthroughMountBypass: true,
          })
        } else {
          updateAppliedFilters(prepareFilterPayload(decodedUrlState.filters || []))
        }
      } catch (e) {
        decodedUrlState = {}
        routeProps.history.push(`${routeProps.match.url}`)
      }

      this.setState(
        {
          filters:
            isBypassSavedFilters || processedUrlState
              ? []
              : [...this.state.filters].concat(savedFilters, decodedUrlState.filters || []),
          timePeriod:
            decodedUrlState.timePeriod && Object.keys(decodedUrlState.timePeriod).length
              ? decodedUrlState.timePeriod
              : timePeriodValue,
          secondaryTimePeriod: {
            ...this.state.selectedTimePeriod,
            secondaryTimePeriods: !isEmpty(decodedUrlState.secondaryTimePeriod)
              ? decodedUrlState.secondaryTimePeriod
              : secTimePeriodValue,
          },
        },
        () => {
          if ((!decodedUrlState.filters || !decodedUrlState.filters.length) && !savedFilters.length) {
            this.cardRender()
          }
        }
      )
    }
  }

  handleTimePeriodChange = timePeriod => {
    this.setState(
      {
        timePeriod,
      },
      () => {
        this.cardRender()
      }
    )
  }

  refreshCard = () => {
    this.cardRender()
  }

  checkUserAccess = () => {
    this.props.getUserAccess()
  }

  /**
   * @param {isDrillthrough} bool manually trigger time period request payloads as the viztype for drillthroughs will be undefined when this is called
   */
  cardRender = isDrillthrough => {
    const { drill, timePeriod, filters, swapDrillColumn, cardId, selectedTimePeriod, secondaryTimePeriod } = this.state
    const { renderCardStatus, cardRender } = this.props
    const primaryTimePeriod = selectedTimePeriod?.timePeriod ? cloneDeep(selectedTimePeriod?.timePeriod) : timePeriod
    const secondaryTimePeriods =
      selectedTimePeriod?.secondaryTimePeriods ||
      secondaryTimePeriod?.secondaryTimePeriods ||
      cloneDeep(renderCardStatus[cardId]?.data?.context?.rendered_payload?.secondary_time_periods)
    const vizType = renderCardStatus[cardId]?.data?.card_config?.card_attribute?.viz_type || ''
    const isCompareBy = Boolean(
      renderCardStatus[cardId]?.data?.card_config?.card_query_attribute?.time_period?.compare_by
    )

    const payload = {
      drill,
      filters,
      time_period: primaryTimePeriod,
      secondary_time_periods: secondaryTimePeriods || {},
      swap_drill_column: swapDrillColumn,
    }

    if (
      ['table', 'pivot', 'line', 'bar', 'bar and line', 'stack bar', '100_percent_stack', 'heatmap', 'pareto'].includes(
        vizType.toLowerCase()
      ) ||
      isDrillthrough
    ) {
      payload.result_form = isCompareBy
        ? { date: '${raw}||${formatted}||${alternate}||${alternate_formatted}', pivot: 'formatted' } // eslint-disable-line no-template-curly-in-string
        : { date: '${raw}||${formatted}', pivot: 'formatted' } // eslint-disable-line no-template-curly-in-string
    }
    this.setState({ selectedTimePeriod: null }, () => {
      cardRender({
        card: cardId,
        post: payload,
        vizType,
        ...(this.state.executeAs && { executeAs: this.state.executeAs }),
      })
    })
  }

  getDrillFilters = filter => {
    const { cardInfoStatus } = this.props
    // remove ref_id from drill-through filters, eg: "PushAll(explicit dates):r9k5g2a7fqr" should be "PushAll(explicit dates)"
    const breakByPatternArray = filter?.pattern && filter.pattern[0]?.split(':')
    if (breakByPatternArray?.length === 2) {
      const refIdIndex = cardInfoStatus?.data?.card_query_attribute?.columns.findIndex(
        col => col.ref_id === breakByPatternArray[1]
      )
      return refIdIndex > -1
        ? {
            ...filter,
            pattern: [breakByPatternArray[0]],
          }
        : filter
    }
    return filter
  }

  setDrillAndRender = (newDrill = [], drillthroughInfo) => {
    const { cardInfoStatus, routeProps, renderCardStatus, trackEvent } = this.props
    const { filters, timePeriod, drillthrough, topLevelDrillSavedInfo, cardId, topLevelId } = this.state

    if (drillthroughInfo) {
      if (!drillthroughInfo.isBreadCrumb) {
        trackEvent({
          event: {
            type: 'drillthrough-clicked',
          },
        })
        if (drillthroughInfo.activeDrillthroughIndex > 0) {
          const _drillthrough = cloneDeep(drillthrough)

          _drillthrough[drillthroughInfo.activeDrillthroughIndex - 1].filters = filters
          _drillthrough[drillthroughInfo.activeDrillthroughIndex - 1].timePeriod = timePeriod

          this.setState({ drillthrough: _drillthrough })
        } else {
          this.setState({
            topLevelDrillSavedInfo: {
              filters,
              timePeriod,
            },
          })
        }
      }

      const info = drillthroughInfo.drillthrough[drillthroughInfo.activeDrillthroughIndex]
      const id = drillthroughInfo.id || drillthroughInfo.card_id || info.card_id

      if (info && Object.keys(info) && info.dashboard_id) {
        routeProps.history.push(`/dashboard/${info.dashboard_id}`)
      } else if (drillthroughInfo.time) {
        const granularities = ['Day', 'Week', 'Month', 'Quarter', 'Year', 'All']
        const currentGranularity = timePeriod.granularity
        const granularityIndex = granularities.findIndex(gran => gran === currentGranularity)
        const newTime = drillthroughInfo.time.split('||')[0]
        const renderedInterval = renderCardStatus[cardId].data.rendered_interval
        const filteredFilters = drillthroughInfo.filter
          ? drillthroughInfo.filter
              .filter(filt => filt.dimension !== '__time')
              .map(filter => this.getDrillFilters(filter))
          : []

        if (
          (currentGranularity && currentGranularity !== 'All') ||
          (renderedInterval.granularity && renderedInterval.granularity !== 'All')
        ) {
          const newTimePeriod = {
            type: 'absolute',
            calendar_type: timePeriod.calendar_type || 'Fiscal',
            granularity: currentGranularity ? granularities[granularityIndex] : renderedInterval.granularity || 'Year',
            interval: `'${newTime}'.${currentGranularity || renderedInterval.granularity || 'All'}`,
          }
          this.setState(
            {
              drill: [],
              cardId: id,
              isTimePeriodDrillThrough: true,
              timePeriod: newTimePeriod,
              filters: filteredFilters,
            },
            () => {
              const urlState = `${routeProps.location.search}${routeProps.location.hash}`
              const processedUrlState = urlState.slice(1, urlState.length)

              try {
                const parsedUrlState = processedUrlState ? urlon.parse(processedUrlState) : {}

                parsedUrlState.activeDrillthroughIndex = drillthroughInfo.activeDrillthroughIndex
                parsedUrlState.filters = filteredFilters
                parsedUrlState.timePeriod = newTimePeriod
                routeProps.history.push(`${routeProps.match.url}${`?${urlon.stringify(parsedUrlState)}`}`)
              } catch (e) {
                routeProps.history.push(routeProps.match.url)
              }
            }
          )
        } else {
          const newTimePeriod = {
            granularity: 'All',
            interval: timePeriod.interval || 'last segment',
            type: 'relative',
          }

          this.setState(
            {
              cardId: id,
              isTimePeriodDrillThrough: false,
              drill: [],
            },
            () => {
              const urlState = `${routeProps.location.search}${routeProps.location.hash}`
              const processedUrlState = urlState.slice(1, urlState.length)

              try {
                const parsedUrlState = processedUrlState ? urlon.parse(processedUrlState) : {}

                parsedUrlState.activeDrillthroughIndex = drillthroughInfo.activeDrillthroughIndex
                parsedUrlState.filters = filteredFilters
                parsedUrlState.timePeriod = newTimePeriod
                routeProps.history.push(`${routeProps.match.url}${`?${urlon.stringify(parsedUrlState)}`}`)
              } catch (e) {
                routeProps.history.push(routeProps.match.url)
              }
            }
          )
        }
      } else {
        if (drillthroughInfo.isBreadCrumb) {
          const savedFilters =
            drillthroughInfo.activeDrillthroughIndex >= 0
              ? drillthrough[drillthroughInfo.activeDrillthroughIndex].filters || []
              : topLevelDrillSavedInfo.filters
          const savedTimePeriod =
            drillthroughInfo.activeDrillthroughIndex >= 0
              ? drillthrough[drillthroughInfo.activeDrillthroughIndex].timePeriod
              : topLevelDrillSavedInfo.timePeriod

          let newUrlState = {}

          if (savedFilters && savedFilters.length) {
            newUrlState = { filters: savedFilters }
          }

          if (drillthroughInfo.activeDrillthroughIndex > -1) {
            newUrlState = {
              ...newUrlState,
              activeDrillthroughIndex: drillthroughInfo.activeDrillthroughIndex,
            }
          }

          newUrlState = {
            ...newUrlState,
            timePeriod:
              drillthroughInfo.activeDrillthroughIndex === -1 && Object.keys(savedTimePeriod).length
                ? savedTimePeriod
                : cardInfoStatus.data.card_query_attribute.time_period,
          }

          this.setState({
            cardId:
              drillthroughInfo.activeDrillthroughIndex > -1
                ? drillthrough[drillthroughInfo.activeDrillthroughIndex].card_id
                : topLevelId,
            filters: savedFilters,
            drill: [],
          })

          routeProps.history.push(`${routeProps.match.url}${newUrlState ? `?${urlon.stringify(newUrlState)}` : ''}`)
        } else {
          this.setState(
            {
              cardId: id,
              isTimePeriodDrillThrough: false,
              timePeriod,
              drill: [],
            },
            () => {
              const urlState = `${routeProps.location.search}${routeProps.location.hash}`
              const processedUrlState = urlState.slice(1, urlState.length)

              try {
                const parsedUrlState = processedUrlState ? urlon.parse(processedUrlState) : {}

                if (
                  Number.isInteger(drillthroughInfo.activeDrillthroughIndex) &&
                  drillthroughInfo.activeDrillthroughIndex > -1
                ) {
                  parsedUrlState.activeDrillthroughIndex = drillthroughInfo.activeDrillthroughIndex
                }

                if (drillthroughInfo.filter && drillthroughInfo.filter.length) {
                  const filters = drillthroughInfo.filter
                    .filter(filt => filt.dimension !== '__time')
                    .map(filter => this.getDrillFilters(filter))
                  parsedUrlState.filters = getUniqueFilters(filters)
                }

                routeProps.history.push(`${routeProps.match.url}${`?${urlon.stringify(parsedUrlState)}`}`)
              } catch (e) {
                routeProps.history.replace(routeProps.match.url)
              }
            }
          )
        }
      }
    } else {
      this.setState(
        {
          drill: [...newDrill],
          swapDrillColumn: {},
        },
        () => {
          this.cardRender()
        }
      )
    }
  }

  setColumnSwapAndRender = (newSwapDrillColumn = {}) => {
    this.setState(
      {
        swapDrillColumn: { ...newSwapDrillColumn },
        drill: [],
      },
      () => {
        this.cardRender()
      }
    )
  }

  reLoadNewCard = cardId => {
    this.setState(
      {
        cardId,
      },
      () => {
        this.reinitializeComponent(cardId)
      }
    )
  }

  render() {
    const { user } = this.props
    const cardId = this.state.cardId || this.props.cardId
    const animationsDisabled = user.data?.application_data.ui.chart_animations_disabled

    if (isNaN(cardId)) {
      return <NotFoundPage />
    }

    return (
      <Cardviewer
        trackEvent={this.props.trackEvent}
        trackCustomEvent={this.props.trackCustomEvent}
        displayServiceErrorMessage={this.props.displayServiceErrorMessage}
        isMobile={this.props.isMobile}
        serviceErrorMessage={this.props.serviceErrorMessage}
        setDrill={this.setDrillAndRender}
        setColumnSwap={this.setColumnSwapAndRender}
        routeProps={this.props.routeProps}
        setHeaderTitle={this.props.setHeaderTitle}
        renderCard={this.props.renderCardStatus}
        cardInfo={this.props.cardInfoStatus}
        cardRender={this.props.cardRender}
        drill={this.state.drill}
        thumbnail={this.props.thumbnail}
        cardId={cardId}
        pagination={this.props.pagination}
        preloadedData={this.state.preloadedData}
        print={this.props.print}
        delCard={this.props.delCard}
        cardDelStatus={this.props.cardDelStatus}
        saveCardFromViewer={this.props.saveCardFromViewer}
        saveCardFromViewerStatus={this.props.saveCardFromViewerStatus}
        reLoadNewCard={this.reLoadNewCard}
        updateAppliedFilters={this.props.updateAppliedFilters}
        cardRenderFilters={this.state.filters}
        onTimePeriodChange={this.handleTimePeriodChange}
        clearCardWithoutRerender={this.props.clearCardWithoutRerender}
        cardSize={this.props.cardSize}
        isHomePage={this.props.isHomePage}
        deviceType={this.props.deviceType}
        drillthrough={this.state.drillthrough}
        activeDrillthroughIndex={this.state.activeDrillthroughIndex}
        refreshCard={this.cardRender}
        checkUserAccess={this.checkUserAccess}
        topLevelId={this.state.topLevelId}
        timePeriod={this.state.timePeriod || this.props.timePeriod}
        secondaryTimePeriod={
          this.props.renderCardStatus[this.state.cardId]?.data?.context?.rendered_payload?.secondary_time_periods ||
          this.state.secondaryTimePeriod
        }
        filterMetadataStatus={this.props.filterMetadataStatus}
        userAccessStatus={this.props.userAccessStatus}
        isTimePeriodDrillThrough={this.state.isTimePeriodDrillThrough}
        drillthroughBreadCrumbBypass={this.state.drillthroughBreadCrumbBypass}
        appliedFilters={this.props.appliedFilters}
        dateFilterStatus={this.props.dateFilterStatus}
        selectedDatasetObj={this.props.cardsDatasetStatus}
        drillCardMetaStatus={this.props.drillCardMetaStatus}
        isEmbed={this.props.isEmbed}
        chart={this.props.chart}
        animationsDisabled={animationsDisabled}
        treeTableCsvData={this.props.treeTableCsvData}
        getSmartExportData={this.props.getSmartExportData}
        smartExportData={this.props.smartExportData}
        anchorDateFilterStatus={this.props.anchorDateFilterStatus}
        restoreCard={this.props.restoreCard}
        updateCardWithoutRerender={this.props.updateCardWithoutRerender}
        updateCardInfoStatusWithoutRender={this.props.updateCardInfoStatusWithoutRender}
        isBadShortUrl={this.state.isBadShortUrl}
        updateSelectedTimePeriod={this.updateSelectedTimePeriod}
        selectedTimePeriod={this.state.selectedTimePeriod}
        clearSelectedTimePeriod={this.clearSelectedTimePeriod}
        restoreDrillCard={this.props.restoreDrillCard}
        objectNotificationStatus={this.props.objectNotificationState}
        deleteObjectNot={this.props.deleteObjectNot}
        user={this.props.user}
        defaultUser={this.props.userDetails}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    cardInfoStatus: state.cards.cardInfoStatus,
    drillCardMetaStatus: state.cards.drillCardMetaStatus,
    renderCardStatus: state.cards.renderCardStatus || {},
    appliedFilters: state.filter.appliedFilters,
    cardDelStatus: state.cards.cardDelStatus,
    saveCardFromViewerStatus: state.cards.saveCardFromViewerStatus,
    filterMetadataStatus: state.metadata.filterMetadataStatus,
    cardsDatasetStatus: state.builder.cardsDatasetStatus,
    dateFilterStatus: state.timeperiod.dateFilterStatus,
    treeTableCsvData: state.cards.treeTableCsvData,
    smartExportData: state.cards.smartExportData || {},
    anchorDateFilterStatus: state.timeperiod.anchorDateFilterStatus,
    siteObj: state.site.siteStatus,
    objectNotificationState: state.notification.getObjectNotificationStatus,
    getNotificationCountStatus: state.notification.getNotificationCountStatus,
    deleteObjectNotificationStatus: state.notification.deleteObjectNotificationStatus,
    user: state.user.userType,
    userAccessStatus: state.user.userAccessStatus,
  }
}

export const mapDispatchToProps = dispatch => ({
  displayServiceErrorMessage(data) {
    dispatch(displayServiceErrorMessage(data))
  },
  getSmartExportData(data) {
    dispatch(getSmartExportData(data))
  },
  cardRender(data) {
    dispatch(cardRender(data))
  },
  getCardMeta(data) {
    dispatch(getCardMeta(data))
  },
  getDrillCardMeta(data) {
    dispatch(getDrillCardMeta(data))
  },
  updateAppliedFilters(data) {
    dispatch(updateAppliedFilters(data))
  },
  clearAllFilters(data) {
    dispatch(clearAllFilters(data))
  },
  delCard(data) {
    dispatch(delCard(data))
  },
  saveCardFromViewer(data) {
    dispatch(saveCardFromViewer(data))
  },
  clearCardWithoutRerender(data) {
    dispatch(clearCardWithoutRerender(data))
  },
  getFilterMetadata(data) {
    dispatch(getFilterMetadata(data))
  },
  updateCardsDataset(data) {
    dispatch(updateCardsDataset(data))
  },
  dateFilter(data) {
    dispatch(dateFilter(data))
  },
  getAnchorDateFilter(data) {
    dispatch(getAnchorDateFilter(data))
  },
  restoreCard(data) {
    dispatch(restoreCard(data))
  },
  restoreDrillCard(data) {
    dispatch(restoreDrillCard(data))
  },
  updateCardWithoutRerender(data) {
    dispatch(updateCardWithoutRerender(data))
  },
  updateCardInfoStatusWithoutRender(data) {
    dispatch(updateCardInfoStatusWithoutRender(data))
  },
  getObjectNot(data) {
    dispatch(getObjectNot(data))
  },
  getNotificationCount(data) {
    dispatch(getNotificationCount(data))
  },
  clearNotificationCache(data) {
    dispatch(clearNotificationCache(data))
  },
  clearDeleteObjectNotificationStatus(data) {
    dispatch(clearDeleteObjectNotificationStatus(data))
  },
  getUserAccess() {
    dispatch(getUserAccess())
  },
})

CardviewerContainer.defaultProps = {
  user: {},
  appliedFilters: [],
  displayServiceErrorMessage: () => {},
  updateAppliedFilters: () => {},
  isMobile: false,
  thumbnail: false,
  pagination: true,
  cardInfoStatus: {},
  renderCardStatus: {},
  routeProps: {},
  setHeaderTitle: () => {},
  cardRender: () => {},
  getCardMeta: () => {},
  getUserAccess: () => {},
  print: false,
  delCard: () => {},
  saveCardFromViewer: () => {},
  saveCardFromViewerStatus: {},
  cardSize: {},
  isHomePage: false,
  filterMetadataStatus: {},
  userAccessStatus: {},
  cardsDatasetStatus: {},
  anchorDateFilterStatus: {},
  deleteObjectNotificationStatus: {},
}

CardviewerContainer.propTypes = {
  user: PropTypes.object,
  updateAppliedFilters: PropTypes.func,
  displayServiceErrorMessage: PropTypes.func,
  appliedFilters: PropTypes.array,
  isMobile: PropTypes.bool,
  cardInfoStatus: PropTypes.object,
  renderCardStatus: PropTypes.object,
  routeProps: PropTypes.object,
  setHeaderTitle: PropTypes.func,
  cardRender: PropTypes.func,
  getCardMeta: PropTypes.func,
  getUserAccess: PropTypes.func,
  delCard: PropTypes.func,
  saveCardFromViewer: PropTypes.func,
  saveCardFromViewerStatus: PropTypes.object,
  cardSize: PropTypes.object,
  isHomePage: PropTypes.bool,
  filterMetadataStatus: PropTypes.object,
  userAccessStatus: PropTypes.object,
  isEmbed: PropTypes.bool,
  anchorDateFilterStatus: PropTypes.object,
  updateCardWithoutRerender: PropTypes.func,
  deleteObjectNotificationStatus: PropTypes.object,
}

export default withAnalytics(analyticsConfig)(connect(mapStateToProps, mapDispatchToProps)(CardviewerContainer))
