import { Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const Title = styled(Typography)`
  && {
    margin: 20px;
    text-align: center;
  }
`
const NotFoundPage = () => (
  <>
    <Title variant="h1">404</Title>
    <Title variant="h2">
      There&apos;s no content here - <Link to="/">Return to Home</Link>
    </Title>
  </>
)

export default NotFoundPage
